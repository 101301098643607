@import url('https://fonts.googleapis.com/css?family=Poppins:400,500,600,700,800,900&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Poppins','Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
}

.slick-prev {
  left: 3% !important;
  z-index: 1;
  padding: 15px;
}
.slick-next {
  right: 3% !important;
  z-index: 1;
}

