@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap");

.editor {
  font-family: -apple-system, BlinkMacSystemFont, 'Poppins','Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    background: #ecf0f1;
    flex: 1;
    > .section-two {
      display: flex;
      flex: 1;
      > .section-three {
        flex: 1;
        display: flex;
        flex-direction: column;
      }
      > .section-four {
        flex: 1;
        display: flex;
        flex-direction: column;
      }
    }
  }
  
  .editor-canvas {
    flex: 1;
  }
  