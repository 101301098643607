.navbar {
    // background: linear-gradient(90deg, #00c4cc, #7d2ae8);
    background-color: #fff;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1.36rem;
  }
  
  .navbar-left {
    color: #fff;
    display: flex;
    svg {
      width: 36px;
      height: 36px;
    }
  }
  
  .navbar-action-items {
    color: #fff;
    display: flex;
  }
  .navshare{
    background-color:rgb(230 233 239);
    border: none;
    color: white;
    padding: 5px 15px;
    text-align: center;
    text-decoration: none;
    // display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
  }
  
  .navbar-icon {
    border-radius: 4px;
    padding: 0.5rem;
    background: rgba($color: #fff, $alpha: 0.15);
    margin-left: 1rem;
    cursor: pointer;
  }
  .logo{
    width: 300px;
    height: 130px;
  }
  