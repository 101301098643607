.contextContainer {
    z-index: 1;
    width: 100%;
    height: 500px;
    background: rgb(102, 89, 227);
  }
  .rightClick {
    z-index: 12;
    position: fixed;
    background: rgb(102, 89, 227);
    color: #fff;
  }
  .menuElement {
    color: #fff;
    cursor: pointer;
    padding: 17px 36px;
    border-bottom: 1px solid #fff;
  }
  .menuElement:hover {
    color: #fff;
    background: #009688;
  }