.panel-item-container {
    width: 0;
    transition: width 0.5s;
    position: relative;
    overflow: hidden;
    display: flex;
    &.open {
      width: 250px;
    }
  }
  .panel-item {
    flex: 1;
  }
  
  .objects-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    // grid-auto-rows: minmax(100px, auto);
    // gap: 1rem;
    padding: 1rem 0;
  }
  
  .object-item {
    // height: 6rem;
    // background: rebeccapurple;
  }
  
  .object-item-container {
    // background: red;
    transition: background 0.4s;
    cursor: pointer;
    padding: 1rem;
    &:hover {
      background: rgba($color: #fff, $alpha: 0.1);
    }
  }
  
  // Panel Text
  .panel-text {
    width: 360px;
    color: #fff;
    .add-text-items {
      display: grid;
      gap: 0.5rem;
    }
    .label {
      font-weight: 600;
      padding: 0.8rem 0;
      font-size: 0.84rem;
    }
    .add-text-item {
      background: rgba($color: #fff, $alpha: 0.1);
      height: 50px;
      display: flex;
      align-items: center;
      padding-left: 1rem;
      cursor: pointer;
      transition: background 0.4s;
      &:hover {
        background: rgba($color: #fff, $alpha: 0.15);
      }
    }
    .add-heading {
      font-weight: 700;
      font-size: 1.66rem;
    }
    .add-subheading {
      background: rgba($color: #fff, $alpha: 0.1);
      font-size: 1.12rem;
      font-weight: 500;
    }
    .add-body-text {
      background: rgba($color: #fff, $alpha: 0.1);
      font-size: 0.76rem;
      font-weight: 400;
      color: rgba($color: #fff, $alpha: 0.9);
    }
  }
  