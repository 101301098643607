//variables
$black: #000;
$white: #fff;
$red: #aa0607;
$fsize: 15px;
$gray: #7b7b7b;
$primary: #6659e3;
$darkprimary: #564ad0;
$primarylight: #e8e6fc;
$blue: #00136c;

$green: #009e1a;
$darkgray: #5b5b5b;
$lightblue: #86d8ff;
$lightprimary: #f6f6f6;
$yellow: #ffbc37;

//devices breakpoints
$xs: 0;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;
$xxxl: 1600px;
